/* @font-face {
  font-family: 'Apis-Bold';
  src: url('../../assets/fonts/Apis-Bold.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Apis-Regular';
  src: url('../../assets/fonts/Apis-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Apis-Medium';
  src: url('../../assets/fonts/Apis-Medium.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
} */

body {
  margin: 0;
}

.border {
  border: none !important
}

.bold {
  font-weight: 600;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.border::before {
  position: absolute;
  content: "";
  background-image: url(/static/images/border-curve.png);
  width: 80px;
  height: 12px;
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  bottom: -8px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

}


.logo-curve::before {
  position: absolute;
  content: "";
  background-image: url(/static/images/logo-curve.png);
  width: 150px;
  height: 15px;
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  bottom: -20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.footer-curve::before {
  position: absolute;
  content: "";
  background-image: url(/static/images/footer_logo_curved.png);
  width: 150px;
  height: 40px;
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  bottom: -70px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

}

.quick-links-curve::before {
  position: absolute;
  content: "";
  background-image: url(/static/images/quick_link_curve.png);
  width: 150px;
  height: 50px;
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  bottom: -50px;
  left: 25%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

}

.font-bold {
  font-weight: bold;
  /* font-family: 'Apis-Bold'; */
}

.font-regular {
  font-weight: 400;
  /* font-family: 'Apis-Regular'; */
}

.font-medium {
  font-weight: 500;
  /* font-family: 'Apis-Medium'; */
}

.secondary-background.active {
  background-color: #98CFEF;
}

.secondary-background {
  background-color: #F8F8F8;
}

.tab {
  padding: 15px;
  box-shadow: 15px 15px 15px 15px #e3e3e388;
}

.main {
  padding-top: 180px;
}

.appBar::after {
  clear: both;
}

.white {
  background-color: white;
}

.curvedBanner {
  position: relative;

}

.curvedBanner::after {
  content: "";
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: -1;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  background-image: url("/static/images/common/curved.svg");
  /* background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200 113' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 1C19.5523 3 32.2291 4 51.5 7C104.5 16 200 36 300 56C400 75 500 94 600 86C700 79 800 45 900 25C987.526 4 1085.36 -1 1150 0C1169.54 -1 1180.49 0 1200 1V113H1150C1100 113 1000 113 900 113C800 113 700 113 600 113C500 113 400 113 300 113C200 113 100 113 50 113H0V1.98128Z' fill='%23ffffff'/></svg>"); */
}

@media only screen and (max-width: 600px) {
  .main {
    padding-top: 120px;
  }
}